<template>
    <v-container fluid>
        <AppPrivacyPolicy/>
    </v-container>
</template>

<script>
    import {
        VContainer,
    } from 'vuetify/lib'

    import AppPrivacyPolicy from '@/components/app/privacy-policy/PrivacyPolicy'

    import { mapMutations } from 'vuex'

    export default {
        name: 'PrivacyPolicy',

        components: {
            VContainer,

            AppPrivacyPolicy,
        },

        data() {
            return {}
        },

        computed: {
            appBarData() {
                return {
                    dark: true,
                    navigationIcon: {
                        type: 'rootBack',
                    },
                    title: 'Политика конфиденциальности',
                }
            },
        },

        mounted() {
            this.setAppBarData()
        },

        methods: {
            ...mapMutations({
                setAppBar: 'ui/setAppBar',
            }),

            setAppBarData() {
                this.setAppBar(this.appBarData)
            },
        },
    }
</script>
